/* info.css */

.lst-kix_nm1veegxbuol-1 > li:before {
  content: "\0025cb   ";
}
.lst-kix_nm1veegxbuol-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_nm1veegxbuol-8 > li:before {
  content: "\0025a0   ";
}
.lst-kix_nm1veegxbuol-5 > li:before {
  content: "\0025a0   ";
}
.lst-kix_nm1veegxbuol-7 > li:before {
  content: "\0025cb   ";
}
ul.lst-kix_nm1veegxbuol-8 {
  list-style-type: none;
}
ul.lst-kix_nm1veegxbuol-7 {
  list-style-type: none;
}
ul.lst-kix_nm1veegxbuol-6 {
  list-style-type: none;
}
ul.lst-kix_nm1veegxbuol-5 {
  list-style-type: none;
}
.lst-kix_nm1veegxbuol-2 > li:before {
  content: "\0025a0   ";
}
ul.lst-kix_nm1veegxbuol-4 {
  list-style-type: none;
}
.lst-kix_nm1veegxbuol-6 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_nm1veegxbuol-3 {
  list-style-type: none;
}
ul.lst-kix_nm1veegxbuol-2 {
  list-style-type: none;
}
ul.lst-kix_nm1veegxbuol-1 {
  list-style-type: none;
}
ul.lst-kix_nm1veegxbuol-0 {
  list-style-type: none;
}
.lst-kix_nm1veegxbuol-3 > li:before {
  content: "\0025cf   ";
}
.lst-kix_nm1veegxbuol-4 > li:before {
  content: "\0025cb   ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c7 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c0 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c6 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}
.c8 {
  padding: 18px;
}
.c1 {
  background-color: #ffffff;
}
.c3 {
  font-weight: 700;
}
.c4 {
  height: 11pt;
}
.c5 {
  font-style: italic;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
