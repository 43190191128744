.App {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.map-button {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 10000;
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.filters {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
}

.filters button {
  margin-bottom: 8px !important;
}

.filters > button {
  border: 1px lightgray solid !important;
  background-color: white;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.filters-title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  padding: 6px 0px;
}
.filter-check {
  padding: 6px 0px;
  text-align: left;
  font-weight: bold !important;
}

.filter-input {
  margin: 6px 0px;
}

.filter-yellow > label {
  color: #fcc41a !important;
}

.filter-blue > label {
  color: #339af0 !important;
}

.filter-green > label {
  color: #21c930 !important;
}

.filter-red > label {
  color: #fa5352 !important;
}

.search-popup {
  padding: 0px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

.search-popup > form {
  margin: 0px !important;
}
